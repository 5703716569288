<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true" v-if="info && info.user_type==1">
      <el-form-item label="">
        <el-select v-model="search.store" clearable placeholder="请选择门店" size="small">
          <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addGroup()">添加产品</el-button>
    </div>
    <el-tabs @tab-click="changeCarModel" v-model="search.model_id">
      <el-tab-pane :label="item.title" :name="'tab_' + item.id" v-for="(item,index) in carModelList" :key="index"></el-tab-pane>
    </el-tabs>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
<!--      <el-table-column-->
<!--          prop="id"-->
<!--          label="序号"-->
<!--          align="center"-->
<!--          width="100">-->
<!--      </el-table-column>-->
      <el-table-column
          prop="menu_id_str"
          label="产品大类"
          align="center">
      </el-table-column>
      <el-table-column
           prop="shop_name"
           label="门店">
      </el-table-column>
      <el-table-column
          prop="title"
          label="产品名称"
          align="left"
          min-width="250">
      </el-table-column>
      <el-table-column
          prop="supplier"
          label="产品供应商"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="cost_price"
          label="成本价格"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="price"
          label="销售价格"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="work_price"
          label="工时费用"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="work_num"
          label="工时"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="month_play_num"
          label="月度目标数"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="technical_commission"
          label="技术提成"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column v-for="(item,index) in fieldList" :key="index"
          :prop="'st-' + item.key"
          :label="item.title"
          align="center"
          width="160">
        <template slot-scope="scope">
          {{scope.row.fields[scope.column.property.replace('st-','')].value || ''}}
        </template> m
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="创建时间"
          align="center"
          width="160">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" style="margin-right: 10px;" @click="editGroup(scope.row)">编辑</el-button>
          <el-popconfirm
              title="确认删除当前类目吗？"
              @confirm="delStaff(scope.row.id)"
          >
            <el-button slot="reference" type="text" size="small" style="color: orangered">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      carModelList: false,
      dialogVisible: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        model_id: '',
        store: ''
      },
      tableData: [],
      storeList: [],
      fieldList: [],
      isShow: false
    };
  },
  created() {
    this.getMyInfo()
    this.getList();
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisiable);
  },
  computed: {},
  methods: {
    handleVisiable(e) {
      if(e.target.visibilityState === 'visible' && this.isShow) {
        this.isShow = false
        this.getList()
      }
    },
    getField(id,call) {
      this.carModelList.forEach(item => {
        if(item.id == id) {
          call(item.fields)
        }
      })
    },
    changeCarModel(e) {
      this.getField(this.search.model_id.replace('tab_',''),res => {
        this.fieldList = res
      })
      this.search.current_page = 1
      this.getList()
    },
    addGroup() {
      if(this.info.user_type==1) {
        if(this.search.store=='') {
          this.fail('请先选择门店')
          return false
        }
        this.isShow = true
        window.open('/information/goods/add?model_id=' + this.search.model_id.replace('tab_','')+'&store_id='+this.search.store)
      } else {
        this.isShow = true
        window.open('/information/goods/add?model_id=' + this.search.model_id.replace('tab_',''))
      }
    },
    editGroup(item) {
      this.isShow = true
      window.open('/information/goods/add?id=' + item.id + '&model_id=' + this.search.model_id.replace('tab_','')+'&store_id='+item.store_id)
    },
    createOk() {
      this.dialogVisible = false
      this.getList()
    },
    getMyInfo() {
      this.$api.merchant.myIndex(res => {
        if(res.errcode==0) {
          this.info = res.data
          if(res.data.user_type==1) {
            this.getStoreList()
          } else {
            this.getCarModelList()
          }
        }
      })
    },
    getStoreList() {
      this.$api.merchant.storeIndex({page_size: 1000}, res => {
        if (res.errcode == 0) {
          this.storeList = res.data.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getCarModelList() {
      this.$api.operate.carModelIndex({page_size: 1000}, res => {
        if (res.errcode == 0) {
          this.carModelList = res.data.data
          if(this.carModelList) {
            this.search.model_id = 'tab_' + this.carModelList[0].id
            this.fieldList = this.carModelList[0].fields
          }
          this.getList();
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        store: that.search.store,
        model_id: that.search.model_id.replace('tab_',''),
      }
      this.$api.operate.goodsIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    delStaff(id) {
      this.$api.operate.goodsDel({id:id}, res => {
        if(res.errcode==0) {
          this.success('删除成功')
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
